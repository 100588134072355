<template>
  <v-container>
    <v-card class="content-card-padding">
      <v-row justify="center">
        <div class="button-container">
          <app-button
            color="red"
            dark
            tag="a"
            class="ma-2"
            href="https://getkickbox.typeform.com/to/ljPgs8"
            target="_blank"
          >
            Configure Redbox
          </app-button>
          <app-button
            color="red"
            dark
            tag="a"
            class="ma-2"
            href="https://getkickbox.typeform.com/to/cYjbPe"
            target="_blank"
          >
            Order Redbox
          </app-button>
        </div>
        <div class="button-container">
          <app-button
            tag="a"
            class="ma-2"
            href="https://getkickbox.typeform.com/to/HPIpF4"
            target="_blank"
          >
            Configure Bluebox
          </app-button>
          <app-button
            class="ma-2"
            tag="a"
            href="https://getkickbox.typeform.com/to/qC7nzH"
            target="_blank"
          >
            Order Bluebox
          </app-button>
        </div>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: 'OrderKickBoxes',
};
</script>

<style scoped>

.button-container {
  display: inline-grid;
}

</style>
